const userMenu = document.getElementById('user-menu')

// Sidebar Toggle
const setupUserMenuToggle = () => {
  userMenu.onclick = () => {
    userMenu.classList.toggle('active')

    // Toggle event listener
    setClickOutsideUserMenu()
  }
}

const outsideUserMenuClickListener = (event) => {
  const withinUserMenu = event.composedPath().includes(userMenu)

  if (withinUserMenu !== true) {
    userMenu.classList.remove('active')
  }
}

const setClickOutsideUserMenu = () => {
  // add events for when user menu is closed
  if (userMenu.classList.contains('active')) {
    document.addEventListener('click', outsideUserMenuClickListener)
  } else {
    document.removeEventListener('click', outsideUserMenuClickListener)
  }
}

if (userMenu) {
  setupUserMenuToggle()
  setClickOutsideUserMenu()
}
