import { MakePatchRequest } from '@/shared/utils'
import { isTablet } from '@/shared_components/breakPoints/index'
const sidebar = document.getElementById('sidebar')
const navItems = document.querySelectorAll('.nav-item')

if (sidebar) {
  // Functions
  const setCurrentPage = () => {
    // Add current-page class to any nav-items that have children that are active
    const activeLink = document.querySelector('.nav-link.active')

    if (activeLink) {
      const parentNavItem = activeLink.closest('.nav-item')
      if (parentNavItem) {
        // only toggle if the sidebar is open
        if (sidebar.classList.contains('sidebar-closed')) {
          parentNavItem.classList.add('current-page')
        } else {
          parentNavItem.classList.add('current-page')
          parentNavItem.classList.add('toggled')
        }
      }
    }

    // Add new-indicator class to any nav-items that have children that are marked as 'new'
    const newNavLinks = document.querySelectorAll('.child-navigation .new')
    if (newNavLinks.length > 0) {
      newNavLinks.forEach((newNavLink) => {
        const parentNavItem = newNavLink.closest('.nav-item').querySelector('.nav-item-name')
        const anchorItem = newNavLink.closest('.nav-item').querySelector('.first-nav-item')
        const dropdownName = newNavLink.closest('.nav-item').querySelector('.dropdown-name')

        if (!parentNavItem || parentNavItem.classList.contains('new-indicator')) {
          return
        }

        parentNavItem.classList.add('new-indicator')
        dropdownName.classList.add('new-indicator')
        anchorItem.classList.add('new-anchor-indicator')
      })
    }
  }

  const setupSideBarToggle = () => {
    const expandCollapseButton = document.getElementById('expand-collapse-btn')
    expandCollapseButton.onclick = () => {
      const isSidebarClosed = sidebar.classList.contains('sidebar-closed')

      // Toggle the sidebar
      sidebar.classList.toggle('sidebar-closed')
      // Persist the toggle to the backend
      MakePatchRequest('/dashboard/toggle_expand_nav')

      // If sidebar is going from open to collapsed then un-toggle the nav-items
      if (!isSidebarClosed) {
        unToggleNavItems(navItems)
      }

      // Reinitialize
      initializeSidebar(true)
    }
  }

  // Mobile menu
  const setupMobileMenuToggle = () => {
    const mobileMenuIconsContainer = document.querySelector('#mobile-menu-icons-container')
    if (mobileMenuIconsContainer) {
      mobileMenuIconsContainer.onclick = () => {
        document.querySelector('body').classList.toggle('mobile-menu-open')
      }
    }
  }

  const outsideSidebarClickListener = (event) => {
    const withinSidebar = event.composedPath().includes(sidebar)

    if (withinSidebar !== true) {
      unToggleNavItems(navItems)
    }
  }

  const setClickOutsideSidebar = () => {
    // add events for when sidebar is collapsed
    if (sidebar.classList.contains('sidebar-closed')) {
      document.addEventListener('click', outsideSidebarClickListener)
    } else {
      document.removeEventListener('click', outsideSidebarClickListener)
    }
  }

  const unToggleNavItems = (navItems) => {
    navItems.forEach((navItem) => {
      navItem.classList.remove('toggled')
    })
  }

  // Initialize
  const initializeSidebar = (reInitialize = false) => {
    if (reInitialize) {
      // On re-initialize
      setCurrentPage()
      setClickOutsideSidebar()
    } else {
      // On first initialize

      // Collapse sidebar on tablet
      if (isTablet) {
        sidebar.classList.add('sidebar-closed')
      }

      setCurrentPage()
      setupSideBarToggle()
      setClickOutsideSidebar()

      // Mobile menu click handler
      setupMobileMenuToggle()

      // Nav item click logic
      navItems.forEach((element) => {
        element.onclick = (e) => {
          // The parent nav item only toggles & doesn't link anywhere
          // so this disables it's default behaviour
          const isFirstNavItem = element.classList.contains('first-nav-item') || element.closest('.first-nav-item')
          if (isFirstNavItem) {
            e.preventDefault()
          }

          // Toggle current navigation item open or closed (nav-item)
          // and first make sure all other nav items are un-toggled (except the current)

          // Get the index of the current element, and then filter it out of the navItems array
          // this enables us to still open/close the currently selected nav-item
          const currentElementIndex = Array.prototype.indexOf.call(navItems, e.currentTarget)
          const filteredNavItems = Array.from(navItems).filter((_, i) => i !== currentElementIndex)

          unToggleNavItems(filteredNavItems)

          // Only toggle if the click comes from a parent nav-item
          if (!e.target.closest('.child-navigation')) {
            element.classList.toggle('toggled')
          }
        }
      })
    }
  }

  initializeSidebar()
}
