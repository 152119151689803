import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    this.timeout = setTimeout(() => this.close(), 5000) // Auto close after 5 seconds
  }
  close() {
    clearTimeout(this.timeout) // Clear the timeout if close is called manually
    this.element.classList.add('animate-slide-down') // Animate out

    this.element.addEventListener('animationend', () => {
      this.element.remove() // Remove from DOM after animate out is complete
    })
  }
}
