import { application } from '../application'

import Shared_FlashController from './flash'
application.register('flash', Shared_FlashController)

import Shared_Modals_BasicModalController from '../shared/modals/basic_modal_controller'
application.register('basic-modal', Shared_Modals_BasicModalController)

import Shared_CookiesBannerController from '../shared/cookies_banner_controller'
application.register('cookies-banner', Shared_CookiesBannerController)

import InformationArchitecture_DismissibleController from './dismissible_controller'
application.register('dismissible', InformationArchitecture_DismissibleController)

import InformationArchitecture_LoadingButtonController from './loading_button_controller'
application.register('loading-button', InformationArchitecture_LoadingButtonController)
