import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dismissible"
export default class extends Controller {
  static values = {
    url: String,
    requestMethod: { type: String, default: 'POST' }
  }

  async close() {
    this.element.remove() // remove current element

    if (this.hasUrlValue) {
      this.request() // make a request to given url
    }
  }

  async request() {
    try {
      // we ignore the response but maybe we can use it for something later?
      fetch(this.urlValue, {
        method: this.requestMethodValue,
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
    } catch (error) {
      // console.error('Error:', error)
    }
  }
}
