// SVG
import { SetupSvg } from '@/shared/utils'
SetupSvg()

// also run on Turbo page loads or restores
document.addEventListener('turbo:load', () => {
  SetupSvg()
})

// Stripe
// import globally so that Stripe can run site wide fraud detection.
// https://www.npmjs.com/package/@stripe/stripe-js#ensuring-stripejs-is-available-everywhere
import '@stripe/stripe-js'

// Stimulus Controllers
import '@/controllers/information_architecture'

// Global Utilities
import '@/pages/app/sidebar'
import '@/pages/app/user-menu'
